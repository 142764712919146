<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <AppLogo />
        <!-- <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Initialiser votre mot de passe 🔒
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            Votre mot de passe doit être différent des précédents mots de passe
            utilisés
          </b-card-text> -->

          <!-- form -->
          <div
            class="auth-reset-password-form mt-2"
            method="POST"
          >
            <!-- email -->
            <b-form-group
              label-for="init-email"
              label="Email"
            >
              <b-form-input
                id="init-email"
                v-model="userEmail"
                :state="$v.userEmail.$error ? false : null"
                name="initMail"
                placeholder="Entrer votre email"
              />
              <div v-if="$v.userEmail.$error">
                <small
                  v-if="$v.userEmail.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
                <small
                  v-if="$v.userEmail.email.$invalid"
                  class="text-danger"
                >Adresse email invalide</small>
              </div>
            </b-form-group>
            <!-- code -->
            <b-form-group
              label-for="init-code"
              label="Code de d'initialisation"
            >
              <b-form-input
                id="init-code"
                v-model="codeSent"
                :state="$v.codeSent.$error ? false : null"
                name="initCode"
                placeholder="Entrez le code reçu par email"
              />
              <div v-if="$v.codeSent.$error">
                <small
                  v-if="$v.codeSent.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
            <!-- password -->
            <PasswordInputField
              v-model="password"
              passed-label="Nouveau mot de passe"
            />
            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmer le mot de passe"
            >
              <b-input-group
                class="input-group-merge"
                :class="$v.cPassword.$error ? 'is-invalid' : null"
              >
                <b-form-input
                  id="reset-password-confirm"
                  v-model="cPassword"
                  class="form-control-merge"
                  :type="password2FieldType"
                  :state="$v.cPassword.$error ? false : null"
                  name="reset-password-confirm"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="password2ToggleIcon"
                    @click="togglePassword2Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <div v-if="$v.cPassword.$error">
                <small
                  v-if="$v.cPassword.isValid.$invalid"
                  class="text-danger"
                >Les mot de passe ne sont pas identiques</small>
              </div>
            </b-form-group>
            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              @click="resetPassword()"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              Définir le mot de passe
            </b-button>
          </div>

          <p class="text-center mt-2">
            <b-link href="/login">
              <feather-icon icon="ChevronLeftIcon" /> Retour à la connexion
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import PasswordInputField from '@/components/PasswordInputField.vue'
import { mapActions } from 'vuex'
import AppLogo from '@/components/AppLogo.vue'

export default {
  setup: () => ({ $v: useVuelidate({ $lazy: true }) }),
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    PasswordInputField,
    BSpinner,
    AppLogo,
  },
  data() {
    return {
      codeSent: '',
      userEmail: '',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      isFormCorrect: false,
      isLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  validations() {
    return {
      codeSent: { required },
      userEmail: { required, email },
      password: { required },
      cPassword: {
        isValid: val => val && val === this.password,
      },
    }
  },
  mounted() {
    this.userEmail = this.$route.query.email || ''
    this.codeSent = this.$route.query.code || ''
  },
  methods: {
    ...mapActions('auth', {
      action_resetPassword: 'resetPassword',
    }),
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      if (this.isLoading) return
      this.isFormCorrect = await this.$v.$validate()
      if (!this.isFormCorrect) return
    },
    async resetPassword() {
      this.isFormCorrect = await this.$v.$validate()
      if (!this.isFormCorrect) return
      if (this.isLoading) return
      this.isLoading = true
      const param = {
        email: this.userEmail,
        code: this.codeSent,
        password: this.password,
        password_confirmation: this.cPassword,
      }
      console.log('resetPassword param::: ', param)
      // return;
      // if (!this.isFormCorrect) return;
      this.action_resetPassword(param)
        .then(res => {
          console.log('response action_resetPassword: ', res)
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Mot de passe initialiser avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({
            name: 'auth-login',
            // query: { email: this.userEmail },
          })
        })
        .catch(err => {
          this.isLoading = false
          console.log('err', err)
          if (error.response.data && error.response.data.length !== 0) {
            this.error = error.response.data
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data || 'Email or Password is Invalid',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
